import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import InfoButton from "./InfoButton";

type BaseItem = {
  name: string;
};

type DropDownProps<T extends BaseItem> = {
  selectedItem: T | undefined;
  label: string;
  items: T[];
  onItemSelect: (item: T) => void;
  infoButtonLink?: string;
};

const DropDown = <T extends BaseItem>({
  selectedItem,
  label,
  items,
  onItemSelect,
  infoButtonLink,
}: DropDownProps<T>) => {
  const { t: translate } = useTranslation();
  const currentIndex = useSelector(
    (state: RootState) => state.team.current_index
  );
  const member = useSelector(
    (state: RootState) => state.team.members[currentIndex]
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const onItemClick = (item: T) => {
    setIsDropdownOpen(false);
    onItemSelect(item);
  };

  return (
    <div className="flex flex-col justify-start text-gray-500 font-semibold text-xs sm:text-sm">
      <div className="flex gap-2">
        <span className="mb-1 text-xs lg:text-sm">{label}</span>
        <div className={infoButtonLink ? "flex -mt-1.5" : "hidden"}>
          <InfoButton
            fill={"#49b632"}
            onClick={() => window.open(infoButtonLink, "_blank", "noreferrer")}
          />
        </div>
      </div>
      <div className="w-full relative">
        <div
          className="dropdown-box"
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <span>
            {selectedItem?.name ??
              (items?.length < 1 && member.sport_type
                ? translate("no_options")
                : translate("choose_an_option"))}
          </span>
          <div
            className={`dropdown-arrow ${
              isDropdownOpen ? "-rotate-[135deg]" : "rotate-45"
            }`}
          ></div>
        </div>
        {isDropdownOpen && (
          <div
            className="dropdown-content"
            onMouseLeave={() => setIsDropdownOpen(false)}
          >
            {items?.map((item, index) => {
              return (
                <div key={index} onClick={() => onItemClick(item)}>
                  {item.name}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropDown;
