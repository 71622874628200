import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CheckInComponent from "./CheckInComponent";
import ParticipantsComponent from "./ParticipantsComponent";
import MembersModal from "../Modals/MembersModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Member } from "../../Types/MemberTypes";
import { setCurrentIndex } from "../../Redux/TeamSlice";
import { isMemberCompleted } from "../../shared/helpers";

const Panel = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const checkInDate = useSelector(
    (state: RootState) => state.team.checkin_date
  );
  const members = useSelector((state: RootState) => state.team.members);
  const [showMembersModal, setShowMembersModal] = useState(false);

  const isEntryInvalid = ({ first_name, last_name, age }: Member) => {
    if (!first_name || !last_name || !age) {
      return true;
    }
    return false;
  };

  const isFormInvalid = members.some(isEntryInvalid);

  const getFirstUncompletedMemberIndex = useMemo(() => {
    const firstUncompletedMemberIndex = members.findIndex(
      (member) => !isMemberCompleted(member)
    );
    if (firstUncompletedMemberIndex >= 0) {
      return firstUncompletedMemberIndex;
    } else return members.length - 1;
  }, [members]);

  return (
    <div className=" flex flex-col lg:justify-evenly lg:items-center lg:flex-row bg-white shadow-lg p-8 w-[90%] lg:w-3/4 -translate-y-[45%] sm:-translate-y-[30%] lg:-translate-y-[65%] ">
      <MembersModal show={showMembersModal} setShow={setShowMembersModal} />
      <CheckInComponent />
      <ParticipantsComponent setShow={setShowMembersModal} />
      <button
        className="bg-bloody-red text-white font-bold text-xl h-9 w-28 my-0 lg:my-4 self-end lg:self-center disabled:bg-red-300"
        onClick={() => {
          navigate("/TeamDetails");
          dispatch(setCurrentIndex(getFirstUncompletedMemberIndex));
        }}
        disabled={!checkInDate || isFormInvalid}
      >
        {translate("continue")}
      </button>
    </div>
  );
};

export default Panel;
