import Panel from "../Components/Panel/Panel";
import CheckInWrapper from "./CheckInWrapper";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { isValidDateString } from "../shared/helpers";
import { addEntry } from "../Redux/TeamSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../Redux/store";

const CheckIn = () => {
  const dispatch = useDispatch();
  const [params] = useSearchParams();
  const [checkinDate, setCheckinDate] = useState();
  const members = useSelector((state: RootState) => state.team.members);

  useEffect(() => {
    const dateOfCheckin = params.get("checkin-date");
    if (params.has("checkin-date") && isValidDateString(dateOfCheckin)) {
      // @ts-ignore: We are ensured that dateString is valid here!
      setCheckinDate(new Date(dateOfCheckin));
    }
    const numberOfMembers = Number.parseInt(params.get("member-count") ?? "");
    if (!Number.isNaN(numberOfMembers) && members.length < numberOfMembers) {
      for (let i = 0; i < numberOfMembers - members.length; i++) {
        dispatch(addEntry());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CheckInWrapper checkinDate={checkinDate}>
      <Panel />
    </CheckInWrapper>
  );
};

export default CheckIn;
