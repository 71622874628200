import { configureStore } from "@reduxjs/toolkit";
import { loadState, saveState } from "./storage";
import TeamSliceReducer from "./TeamSlice";

const persistedState = loadState();
export const store = configureStore({
  reducer: {
    team: TeamSliceReducer,
  },
  preloadedState: persistedState,
});

store.subscribe(() => {
  saveState({
    team: store.getState().team,
  });
});

export type RootState = ReturnType<typeof store.getState>;
