import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import {
  modifiersStyles,
  stylesMultiple,
  stylesSingle,
} from "./DayPickerDefaultProps";
import {
  formatCaption,
  formatMonthCaption,
  formatWeekdayName,
  formatYearCaption,
} from "./DayPickerUtils";
import { DatePickerProps } from "../../Types/DatePickerTypes";
import { useState } from "react";
import { isDayDisabled } from "../../shared/helpers";
import { useTranslation } from "react-i18next";
import { BsCheck } from "react-icons/bs";
import { BiUndo } from "react-icons/bi";

const DatePicker = ({
  label,
  numberOfMonths,
  locale,
  captionLayout,
  disabled,
  setSelectedDay,
  onDateRangeSelect,
  onResetClick,
  onConfirmClick,
  onCloseClick,
  allowedDates,
  labelIdForModal,
  startMonth,
  dateRange,
  defaultMonth,
  confirmButtonText,
}: DatePickerProps) => {
  const { t: translate } = useTranslation();
  const [clickedDay, setClickedDay] = useState<Date | undefined>();
  const isSelected = Boolean(dateRange?.from && dateRange.to);

  return (
    <>
      {numberOfMonths === 1 ? (
        <div className="flex-v-center w-[300px] font-sans  px-4 py-5 pb-12 shadow-lg bg-white relative">
          <div className="w-full text-md text-general font-semibold pb-2 pl-3">
            {label}
          </div>

          <DayPicker
            mode={"single"}
            locale={locale}
            weekStartsOn={1}
            numberOfMonths={numberOfMonths}
            formatters={{
              formatCaption: formatCaption(numberOfMonths),
              formatWeekdayName,
              formatMonthCaption,
              formatYearCaption,
            }}
            captionLayout={captionLayout}
            styles={stylesSingle}
            modifiersStyles={modifiersStyles}
            onDayClick={(e) => {
              setSelectedDay && setSelectedDay(e);
              setClickedDay(e);
            }}
            selected={clickedDay}
            disabled={disabled}
            fromYear={1900}
            toYear={new Date().getFullYear() + 1}
            defaultMonth={defaultMonth}
          />

          <div className="absolute w-full h-[calc((100%-250px)/2)] bottom-0 px-[10%] flex items-center justify-end">
            <button
              className="square-btn bg-bloody-red h-8"
              onClick={onConfirmClick}
              disabled={!clickedDay}
            >
              <input
                type="checkbox"
                id={labelIdForModal}
                className="modal-toggle bg-transparent"
              />
              <label
                htmlFor={labelIdForModal}
                className="w-full h-full text-center cursor-pointer p-2"
              >
                {translate("confirm")}
              </label>
            </button>
          </div>
        </div>
      ) : (
        <>
          <div className="  min-w-min w-full md:w-4/5 flex-h-center font-sans p-8 pb-14 md:shadow-lg relative bg-white ">
            <label
              htmlFor="date-range-modal"
              className=" h-4 md:h-5 w-4 md:w-5 text-general text-sm md:text-lg border-2 px-1 hover:bg-white hover:cursor-pointer absolute top-4 right-4 sm:top-6 sm:right-6 flex items-center justify-center"
              onClick={onCloseClick}
            >
              ✕
            </label>
            <div className="w-[90%] relative">
              <div className="text-[10px] sm:text-xs md:text-sm xl:text-xl text-general font-semibold pb-2">
                {label}
              </div>
              <DayPicker
                mode={"range"}
                locale={locale}
                weekStartsOn={1}
                numberOfMonths={numberOfMonths}
                formatters={{
                  formatCaption: formatCaption(numberOfMonths),
                  formatWeekdayName,
                }}
                styles={stylesMultiple}
                modifiersStyles={modifiersStyles}
                selected={dateRange}
                disabled={(day) =>
                  isDayDisabled(day, allowedDates ? allowedDates : [])
                }
                onSelect={!isSelected ? onDateRangeSelect : undefined}
                month={startMonth}
              />
              <div className="absolute w-full h-max -bottom-11 flex-h-center ">
                <div className="flex items-center gap-2">
                  <button
                    className={`square-btn p-2 pr-1 ${
                      isSelected ? "bg-blue-600" : "bg-blue-300"
                    }`}
                    onClick={onResetClick}
                  >
                    <span className="flex items-center gap-1">
                      <p> {translate("reset")}</p>
                      <BiUndo size={17} />
                    </span>
                  </button>
                  <button
                    className="square-btn bg-bloody-red p-2 pr-1"
                    onClick={onConfirmClick}
                    disabled={!isSelected}
                  >
                    <input
                      type="checkbox"
                      id={labelIdForModal}
                      className="modal-toggle bg-transparent"
                    />
                    <label
                      htmlFor={labelIdForModal}
                      className="w-full text-center cursor-pointer"
                    >
                      <span className="flex items-center gap-1">
                        <p> {confirmButtonText ?? translate("confirm")}</p>
                        <BsCheck size={17} />
                      </span>
                    </label>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default DatePicker;
