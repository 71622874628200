import React from "react";
import { FaCheck } from "react-icons/fa";
import { AiOutlineClockCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { currencyFormat, formatTime } from "../shared/helpers";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import { Product } from "../Types/MemberTypes";

type TimeSlotOptionsProps = {
  product: Product;
  days: number;
  onSelectedTimeChange: (time: any) => void;
};

const TimeSlotOptions = ({
  product,
  days,
  onSelectedTimeChange,
}: TimeSlotOptionsProps) => {
  const { t: translate } = useTranslation();
  const currentIndex = useSelector(
    (state: RootState) => state.team.current_index
  );
  const member = useSelector(
    (state: RootState) => state.team.members[currentIndex]
  );

  const { available_times } = product;

  if (!available_times || available_times.length <= 0) {
    return (
      <>
        {translate("there_are_no_available_options_that_match_your_selection")}
      </>
    );
  }

  const onTimeChanged = (e: React.ChangeEvent<HTMLInputElement>, time: any) => {
    if (!e.target.checked) {
      return;
    }
    return onSelectedTimeChange(time);
  };

  return (
    <div>
      {!(member.date_range?.begin_date || member.date_range?.end_date) && (
        <div className="custom-input hover:cursor-default mb-2">
          {"" + translate("please_select_a_date_beforehand")}
        </div>
      )}
      <div className="w-full flex flex-col">
        {available_times?.map((time, index) => {
          const { timeOneFrom, timeOneTo, timeTwoFrom, timeTwoTo, uuid } = time;
          return (
            <div
              className="radio-btn w-full h-fit border border-gray-500 mb-2"
              key={index}
            >
              <input
                type="radio"
                name="timeslot"
                id={uuid}
                onChange={(e) => onTimeChanged(e, time)}
                checked={uuid === member.time?.uuid}
              />
              <label htmlFor={uuid}>
                <div className="bg-transparent w-full h-full flex items-center justify-center relative">
                  <AiOutlineClockCircle size={23} />
                  <div className="sm:w-max pl-3 pr-5 flex flex-col sm:flex-row flex-wrap sm:gap-x-3 justify-center">
                    <span className=" flex-grow w-auto min-w-max max-w-max flex gap-1 sm:gap-3">
                      {formatTime(timeOneFrom) +
                        " " +
                        translate("o_clock") +
                        " - " +
                        formatTime(timeOneTo) +
                        " " +
                        translate("o_clock") +
                        " "}
                      {timeTwoFrom && timeTwoTo && (
                        <span>{translate("and")}</span>
                      )}
                    </span>
                    {timeTwoFrom && timeTwoTo && (
                      <span className=" flex-grow w-auto min-w-max max-w-max">
                        {formatTime(timeTwoFrom) +
                          " " +
                          translate("o_clock") +
                          " - " +
                          formatTime(timeTwoTo) +
                          " " +
                          translate("o_clock")}
                      </span>
                    )}

                    <span className="sm:text-left">
                      {currencyFormat(product.price) +
                        " " +
                        translate("for") +
                        " " +
                        days +
                        " " +
                        (days !== 1 ? translate("days") : translate("day"))}
                    </span>
                  </div>
                  <div className="absolute right-1">
                    <FaCheck size={16} className="text-white" />
                  </div>
                </div>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TimeSlotOptions;
