import { de } from "date-fns/locale";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import {
  editMember,
  resetSelections,
  setCheckInDate,
} from "../../Redux/TeamSlice";
import { dateFormatter, toDateArgument } from "../../shared/helpers";
import DatePicker from "../DatePicker/DatePicker";
import { MEMBER_KEYS } from "../../shared/enums";

type DatePickerModalProps = {
  checkinDate?: Date;
};

const CheckinDatePickerModal = ({ checkinDate }: DatePickerModalProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const members = useSelector((state: RootState) => state.team.members);
  const selectedCheckInDate = useSelector(
    (state: RootState) => state.team.checkin_date
  );

  const [date, setDate] = useState<Date | undefined>(
    selectedCheckInDate
      ? new Date(toDateArgument(selectedCheckInDate))
      : undefined
  );

  useEffect(() => {
    if (
      checkinDate &&
      !selectedCheckInDate &&
      dateFormatter(checkinDate) !== selectedCheckInDate
    ) {
      dispatch(
        setCheckInDate({
          checkin_date: dateFormatter(checkinDate),
        })
      );
      resetTeamSelections();
      setDate(checkinDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkinDate, selectedCheckInDate]);

  const resetTeamSelections = () => {
    members.forEach((member, index) => {
      dispatch(resetSelections(index));
      dispatch(
        editMember({
          index: index,
          key: MEMBER_KEYS.Skill_Level,
          value: undefined,
        })
      );
    });
  };

  const onConfirmClick = () => {
    dispatch(setCheckInDate({ checkin_date: dateFormatter(date) }));
    if (dateFormatter(date) !== selectedCheckInDate) {
      resetTeamSelections();
    }
  };

  return (
    <div>
      <DatePicker
        label={translate("choose_a_date") + ":"}
        numberOfMonths={1}
        locale={de}
        selectedDay={date}
        setSelectedDay={setDate}
        disabled={{ before: new Date() }}
        onConfirmClick={onConfirmClick}
        labelIdForModal="date-single-modal"
      />
    </div>
  );
};

export default CheckinDatePickerModal;
