type infoProps = {
  fill: string;
};

const InfoIcon = ({ fill }: infoProps) => {
  const fillISymbol = fill === "white" ? "green" : "#fff";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <g id="info" transform="translate(0 -0.113)">
        <rect
          id="Rectangle_88"
          data-name="Rectangle 88"
          width="18"
          height="18"
          transform="translate(0 0.113)"
          fill={fill}
        />
        <path
          id="Icon_awesome-info"
          data-name="Icon awesome-info"
          d="M.5,10.6H1V6.994H.5a.5.5,0,0,1-.5-.5V5.3a.5.5,0,0,1,.5-.5H3.3a.5.5,0,0,1,.5.5V10.6h.5a.5.5,0,0,1,.5.5V12.3a.5.5,0,0,1-.5.5H.5a.5.5,0,0,1-.5-.5V11.1A.5.5,0,0,1,.5,10.6ZM2.4,0A1.8,1.8,0,1,0,4.2,1.8,1.8,1.8,0,0,0,2.4,0Z"
          transform="translate(6.488 2.769)"
          fill={fillISymbol}
        />
      </g>
    </svg>
  );
};

export default InfoIcon;
