import { AiOutlineCalendar } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";

const CheckInComponent = () => {
  const { t: translate } = useTranslation();
  const checkInDate = useSelector(
    (state: RootState) => state.team.checkin_date
  );

  return (
    <>
      <label htmlFor="date-single-modal">
        <div className="flex flex-col justify-center  sm:ml-[31%] lg:ml-0 relative self-start lg:self-center  ">
          <div className="flex lg:items-center gap-2 ">
            <AiOutlineCalendar
              size={18}
              color="gray"
              className="hover:cursor-pointer"
            />
            <p className="font-bold hover:cursor-pointer text-sm xl:text-lg ">
              {checkInDate
                ? translate("check_in") + ": " + checkInDate
                : translate("check_in")}
            </p>
          </div>
          <p className="font-light text-xs xl:text-sm text-gray-400 ml-7 hover:cursor-pointer">
            {checkInDate ? "" : translate("start_date")}
          </p>
        </div>
      </label>
      <div className="divider divider-vertical py-5 lg:divider-horizontal lg:py-0 before:bg-gray-500 after:bg-gray-500"></div>
    </>
  );
};

export default CheckInComponent;
