import { FaSkiing } from "react-icons/fa";

const AlpineSkiing = () => {
  return (
    <div>
      <FaSkiing />
    </div>
  );
};

export default AlpineSkiing;
