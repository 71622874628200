import React from "react";
import { AiOutlineCalendar, AiOutlineClockCircle } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import AlpineSkiing from "../../Assets/Icons/Course/AlpineSkiing";
import { calculateCourseTimes } from "../../shared/helpers";

export const getCourseIconName = (course: string) => {
  return AlpineSkiing;
};

type IconProps = {
  name: React.FunctionComponent | any;
};

type SelectionDetailsItemProps = {
  icon: JSX.Element;
  title: string;
  description: string;
};

export type SelectionDetailsProps = {
  currentIndex: number;
  onModalClose: React.MouseEventHandler<HTMLSpanElement> | undefined;
};

const Icon = ({ name }: IconProps) => {
  if (name) {
    const icon = React.createElement(name);
    return <div className=" text-xl text-general">{icon}</div>;
  } else return <></>;
};

const SelectionDetailItem = ({
  icon,
  title,
  description,
}: SelectionDetailsItemProps) => {
  return (
    <div className="flex items-start">
      <span>{icon}</span>
      <div className="flex flex-col ml-3">
        <span className="text-general font-sans text-xs font-bold">
          {title}
        </span>
        <span className="text-gray-500 text-[10px] font-semibold">
          {description}
        </span>
      </div>
    </div>
  );
};

const SelectionDetailsModal = ({
  currentIndex,
  onModalClose,
}: SelectionDetailsProps) => {
  const { t: translate } = useTranslation();
  const member = useSelector(
    (state: RootState) => state.team.members[currentIndex]
  );

  const { beginTime, endTime } = calculateCourseTimes(member.time);

  return (
    <div className=" menu w-max gap-2 border p-4 bg-white ">
      <span
        className="text-red-500 text-lg font-thin absolute top-0 right-2 hover:cursor-pointer"
        onClick={onModalClose}
      >
        ✕
      </span>
      <SelectionDetailItem
        icon={<Icon name={AiOutlineCalendar} />}
        title={translate("period")}
        description={
          (member.date_range?.begin_date
            ? member.date_range?.begin_date + " "
            : "-") +
          " " +
          translate("until") +
          " " +
          (member.date_range?.end_date ? member.date_range?.end_date : "-")
        }
      />
      <SelectionDetailItem
        icon={<Icon name={AiOutlineClockCircle} />}
        title={translate("start_time")}
        description={
          beginTime +
          " " +
          translate("o_clock") +
          " - " +
          endTime +
          " " +
          translate("o_clock")
        }
      />
      <SelectionDetailItem
        icon={
          member.sport_type?.icon?.url ? (
            <img
              src={member.sport_type?.icon?.url}
              className="w-5 h-5"
              alt={member.sport_type?.name}
            />
          ) : (
            <Icon
              name={
                member.sport_type?.id
                  ? getCourseIconName(member.sport_type?.id)
                  : "p"
              }
            />
          )
        }
        title={translate("chosen_sport")}
        description={member.sport_type ? member.sport_type?.name : "-"}
      />
      <SelectionDetailItem
        icon={
          <div
            style={{ backgroundColor: member.skill_level?.colorCode }}
            className="w-5 h-5 rounded-full"
          />
        }
        title={translate("difficulty")}
        description={member.skill_level?.name ?? "-"}
      />
    </div>
  );
};

export default SelectionDetailsModal;
