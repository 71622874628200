import { Styles } from "react-day-picker";
import { ModifiersStyles } from "react-day-picker";
import {
  bloodyRed,
  grayHighlight,
  lightGray,
  textGeneral,
} from "../../shared/const-data";

export const stylesSingle: Styles = {
  root: {
    padding: "10px",
    margin: "0",
    border: `1px solid ${lightGray}`,
    width: "90%",
    height: "250px",
  },

  caption: {
    padding: 0,
  },

  button: {
    cursor: "pointer",
    padding: "0",
  },

  caption_label: {
    fontSize: "24px",
    textTransform: "uppercase",
    color: textGeneral,
  },
  dropdown_year: {
    fontSize: "12px",
  },

  nav_button_next: {
    backgroundColor: bloodyRed,
    color: "white",
    borderRadius: "0",
    border: "none",
    height: "20px",
    width: "20px",
    outline: "none",
    marginRight: "10px",
  },

  nav_button_previous: {
    backgroundColor: bloodyRed,
    color: "white",
    borderRadius: "0",
    border: "none",
    height: "20px",
    width: "20px",
    outline: "none",
    marginRight: "10px",
    marginLeft: "10px",
  },

  table: {
    color: textGeneral,
    fontSize: "16px",
    fontWeight: "bold",
    textTransform: "uppercase",
    padding: "25px",
  },

  head_row: {
    borderBottom: `1px solid ${lightGray}`,
    marginBottom: "10px",
    height: "max-content",
  },

  head_cell: {
    height: "30px",
    width: "30px",
  },

  tbody: {
    margin: "10px 20px 20px 20px",
    fontSize: "12px",
  },

  months: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    flexWrap: "wrap",
  },

  multiple_months: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    width: "80%",
  },

  cell: {
    height: "28px",
    width: "28px",
    padding: "0",
  },

  day: {
    display: "",
    border: "none",
    width: "100%",
    height: "100%",
    margin: "0",
  },

  row: {},
};

export const stylesMultiple: Styles = {
  ...stylesSingle,
  root: {
    padding: "10px",
    margin: "0",
    border: `1px solid ${lightGray}`,
    width: "100%",
  },
  months: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  month: {
    height: "260px",
  },
  cell: {
    height: "30px",
    width: "30px",
    padding: "0",
    fontSize: "12px",
    fontWeight: "500",
  },
  caption: {
    marginBottom: "15px",
  },
  caption_label: {
    fontSize: "20px",
    textTransform: "uppercase",
    color: textGeneral,
  },
  head_cell: {
    fontSize: "12px",
  },
};

export const modifiersStyles: ModifiersStyles = {
  selected: {
    backgroundColor: "transparent",
    color: textGeneral,
    border: `1px solid ${bloodyRed}`,
    borderRadius: "0",
  },

  today: {},

  range_start: {
    border: `3px solid ${bloodyRed}`,
  },

  range_middle: {
    border: "none",
    height: "100%",
    width: "100%",
    backgroundColor: grayHighlight,
  },
  range_end: {
    border: `3px solid ${bloodyRed}`,
  },
};
