import { createSlice } from "@reduxjs/toolkit";
import { MAX_NUM_OF_MEMBERS } from "../shared/const-data";
import { Member, MemberActionPayload } from "../Types/MemberTypes";

type InitialState = {
  members: Member[];
  current_index: number;
  checkin_date: string | undefined;
};

const initialState: InitialState = {
  members: [
    {
      first_name: "",
      last_name: "",
      age: 0,
    },
  ],
  current_index: 0,
  checkin_date: undefined,
};
const TeamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    editMember: (state: InitialState, action: MemberActionPayload) => {
      const { index, key, value } = action.payload;
      state.members[index] = { ...state.members[index], [key]: value };
    },

    deleteMember: (state: InitialState, action) => {
      const index: number = action.payload;
      if (state.members.length === 1) {
        state.members = [
          {
            first_name: "",
            last_name: "",
            age: 0,
          },
        ];
      } else state.members.splice(index, 1);
    },

    addEntry: (state: InitialState) => {
      const slot: Member = {
        first_name: "",
        last_name: "",
        age: 0,
      };
      if (state.members.length !== MAX_NUM_OF_MEMBERS) {
        state.members.push(slot);
      }
    },

    setCurrentIndex: (state: InitialState, action) => {
      state.current_index = action.payload;
    },
    setCheckInDate: (state: InitialState, action) => {
      state.checkin_date = action.payload.checkin_date;
    },
    resetSelections: (state: InitialState, action) => {
      const index: number = action.payload;
      state.members[index] = {
        ...state.members[index],
        date_range: undefined,
        time: undefined,
        cross_selling_products: undefined,
        cost: 0,
        course_duration: 0,
        product: undefined,
        products: undefined,
      };
    },

    resetTimeSelection: (state: InitialState, action) => {
      const index: number = action.payload;
      state.members[index] = {
        ...state.members[index],
        time: undefined,
      };
    },

    setTotalCost: (state: InitialState, action) => {
      const index: number = action.payload;
      let crossSellingProductsCost = 0;
      state.members[index].cross_selling_products?.forEach(
        (product) => (crossSellingProductsCost += product.price)
      );
      const courseCost = state.members[index]?.product?.price;
      state.members[index] = {
        ...state.members[index],
        cost: crossSellingProductsCost + (courseCost ? courseCost : 0),
      };
    },
  },
});

export const {
  addEntry,
  editMember,
  deleteMember,
  setCurrentIndex,
  setCheckInDate,
  resetSelections,
  resetTimeSelection,
  setTotalCost,
} = TeamSlice.actions;
export default TeamSlice.reducer;
