import { useTranslation } from "react-i18next";
import large from "../Assets/Large.png";
import watermark from "../Assets/styleflasher_watermark.svg";
import mobile from "../Assets/Mobile.jpg";
import AgePickerModal from "../Components/Modals/AgePickerModal";
import CheckinDatePickerModal from "../Components/Modals/CheckinDatePickerModal";
import useWindowSize from "../Hooks/useWindowSize";

type Props = {
  checkinDate?: Date;
  children: JSX.Element;
};
const CheckInWrapper = ({ checkinDate, children }: Props) => {
  const { t: translate } = useTranslation();
  const width = useWindowSize();

  return (
    <div className="h-screen w-screen flex-h-center ">
      <div className="w-[90%] md:w-[85%] h-[90%] 2xl:h-3/4 flex flex-col items-center ">
        <input
          type="checkbox"
          id="date-age-modal"
          className="modal-toggle bg-transparent"
        />
        <label htmlFor="date-age-modal" className="modal backdrop-blur-sm">
          <label htmlFor="">
            <AgePickerModal />
          </label>
        </label>

        <input
          type="checkbox"
          id="date-single-modal"
          className="modal-toggle bg-transparent"
        />
        <label htmlFor="date-single-modal" className="modal backdrop-blur-sm">
          <label htmlFor="">
            <CheckinDatePickerModal checkinDate={checkinDate} />
          </label>
        </label>
        <div className="styleflasher-bg-1">
          <img
            src={width >= 1024 ? large : mobile}
            alt="Winter Sports"
            className="h-full lg:h-auto max-h-full w-full -mt-[20px] sm:mt-0 relative"
          >

          </img>
          <a className="flex flex-col absolute z-50 mt-56 xs:mt-60 lg:mt-2"
             href="https://www.styleflasher.at/" target="_blank" rel="noreferrer">
            <p className="font-bold text-[7px] -mb-2">MADE WITH STYLE BY</p>
            <img
                src={watermark}
                alt="styleflasher Logo"
                className="w-[110px] h-7 bg-no-repeat"
            ></img>
          </a>
        </div>
        <div className="absolute top-[15%] 2xl:top-[25%] left-[10%] md:left-[12%] lg:left-[18%] text-2xl sm:text-4xl xl:text-5xl 2xl:text-6xl font-black text-white tracking-normal flex flex-col gap-2 md:gap-4">
          <span>{translate("stress-free") + "."}</span>
          <span>{translate("book_ski_course_directly") + "!"}</span>
          <span>{translate("start_straight_on_the_mountain") + "!"}</span>
        </div>
        <div className="w-full flex justify-center">{children}</div>
      </div>
    </div>
  );
};

export default CheckInWrapper;
