import { gql } from "@apollo/client";

export const GET_BOOKING_PERIODS = gql`
  query bookingPeriods($input: BookingPeriodInput!) {
    bookingPeriods(input: $input) {
      startDate
      endDate
      durationInDays
    }
  }
`;

export const GET_SPORT_TYPES = gql`
  query sportTypes {
    sportTypes {
      id
      name
      icon {
        url
      }
    }
  }
`;

export const GET_SKILL_LEVELS = gql`
  query skillLevels($skillLevelInput: SkillLevelInput!) {
    skillLevels(input: $skillLevelInput) {
      uuid
      name
      icon {
        url
      }
      colorCode
    }
  }
`;

export const GET_START_DATES = gql`
  query startDates($input: StartDateInput!) {
    startDates(input: $input)
  }
`;

export const GET_BOOKING_PRODUCTS = gql`
  query bookingProducts($input: BookingProductInput!) {
    bookingProducts(input: $input) {
      price {
        ...priceFragment
      }
      saProductUuid
      startTimes {
        uuid
        timeOneFrom
        timeOneTo
        timeTwoFrom
        timeTwoTo
        periodType
      }
      product {
        id
        name
        productNumber
        description
      }
      crossSellingProducts {
        product {
          name
          id
        }
        qty
        saProductUuid
        price {
          ...priceFragment
        }
      }
    }
  }

  fragment priceFragment on BookingProductPrice {
    price {
      totalPrice
    }
    sessionUuid
    priceListUuid
  }
`;
