import {
  getInitials,
  currencyFormat,
  isMemberCompleted,
  getTotalTeamCost,
} from "../shared/helpers";
import InfoButton from "../shared/InfoButton";
import { brightGreen } from "../shared/const-data";
import { useTranslation } from "react-i18next";
import { Member } from "../Types/MemberTypes";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import { useState } from "react";
import SelectionDetailsModal from "./Modals/SelectionDetailsModal";

type TotalCostItem = {
  name: String;
  surname: String;
  value: number;
  index: number;
};

const TotalCost = () => {
  const { t: translate } = useTranslation();
  const members: Member[] = useSelector(
    (state: RootState) => state.team.members
  );
  const [itemIndex, setItemIndex] = useState<number>(-1);
  const totalCost: number = getTotalTeamCost(members);

  const onModalClose = () => {
    setItemIndex(-1);
  };

  const CostPerMember = ({ name, surname, value, index }: TotalCostItem) => {
    return (
      <div className="flex justify-between max-h-[22px] mr-1 w-auto font-sans text-sm text-general font-bold">
        <div className="flex">
          <span className="align-middle min-w-[30px]">
            {getInitials(name, surname)}
          </span>
          <span
            className="relative flex items-center"
            onMouseLeave={onModalClose}
          >
            <InfoButton
              fill={brightGreen}
              onMouseEnter={() => setItemIndex(index)}
              onClick={() => setItemIndex(index)}
            />
            {itemIndex === index && (
              <div
                className="absolute left-4 top-2 z-50"
                onMouseLeave={onModalClose}
              >
                <SelectionDetailsModal
                  currentIndex={itemIndex}
                  onModalClose={onModalClose}
                />
              </div>
            )}
          </span>
        </div>
        <div className="align-middle">{currencyFormat(value)} </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col w-48 md:w-64 p-1 shadow-lg font-sans text-sm text-general font-bold bg-white">
      <div className=" flex flex-col m-2 sm:gap-1">
        {members.map((member, index) => {
          if (isMemberCompleted(member)) {
            return (
              <CostPerMember
                key={index}
                name={member.first_name}
                surname={member.last_name}
                value={member.cost ? member.cost : 0}
                index={index}
              />
            );
          } else return null;
        })}
      </div>

      <div
        className={`mx-2 my-1 pt-1 border-general flex justify-between ${
          totalCost ? "border-t-2" : ""
        }`}
      >
        <span>{translate("total_price")}</span>
        <span className="ml-2 mr-1">{currencyFormat(totalCost)}</span>
      </div>
    </div>
  );
};

export default TotalCost;
