import { FiUser } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import { ParticipantsComponentProps } from "../../Types/CheckInTypes";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";

const ParticipantsComponent = ({ setShow }: ParticipantsComponentProps) => {
  const { t: translate } = useTranslation();
  const members = useSelector((state: RootState) => state.team.members);

  const numberOfMembers: number = members.length;
  return (
    <>
      <div
        className="flex flex-col justify-center sm:ml-[31%] lg:ml-0 relative self-start lg:self-center "
        onClick={() => setShow(true)}
      >
        <div className="flex lg:items-center gap-2">
          <FiUser size={18} color="gray" className="hover:cursor-pointer" />
          <p className="font-bold hover:cursor-pointer text-sm xl:text-lg ">
            {numberOfMembers && members[0].first_name
              ? translate("participants") +
                ": " +
                numberOfMembers +
                " " +
                translate("persons")
              : translate("participants")}
          </p>
        </div>
        <p className="font-light text-xs xl:text-sm text-gray-400 ml-7 hover:cursor-pointer">
          {numberOfMembers && members[0].first_name
            ? ""
            : translate("add_participants")}
        </p>
      </div>
      <div className="divider divider-vertical py-5 lg:divider-horizontal lg:py-0 before:bg-gray-500 after:bg-gray-500"></div>
    </>
  );
};

export default ParticipantsComponent;
