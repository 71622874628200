import { ProductDetailModalProps } from "../../Types/CheckInTypes";
import React, { useRef } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import InfoButton from "../../shared/InfoButton";
import { BsXLg } from "react-icons/bs";

const ProductDetailModal = ({
  show,
  setShow,
  product,
}: ProductDetailModalProps) => {
  const modalRef = useRef(null);
  useOutsideClick(modalRef, () => {
    setShow(false);
  });

  return (
    <div
      ref={modalRef}
      className={`absolute left-0 top-0 w-full h-full z-10 shadow-lg p-4 backdrop-blur-sm bg-slate-800/20 text-gray-500 flex flex-col justify-center items-center ${
        show ? "block" : "hidden"
      }`}
    >
      <div className="bg-white p-8 sm:p-12 lg:p-14 w-[100%] sm:w-[90%] md:w-[80%] relative">
        <button
          className="w-min bg-bloody-red absolute top-4 right-4 p-0.5 hover:scale-110 transition-all duration-300 hover:bg-bloody-red/90"
          onClick={() => setShow(false)}
        >
          <BsXLg size={40} className="fill-white w-7 h-7 p-1" />
        </button>

        <div className="flex gap-4 mb-4 mt-6 sm:mt-0">
          <InfoButton fill={"green"} />
          <h1 className="text-general text-base md:text-xl font-semibold">
            {product.name}
          </h1>
        </div>
        <div
          className="text-xs lg:text-sm font-base mb-1 flex flex-col gap-4 sm:gap-3"
          dangerouslySetInnerHTML={{
            __html: product.descriptionHtml,
          }}
        />
      </div>
    </div>
  );
};

export default ProductDetailModal;
