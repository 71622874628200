export enum MEMBER_KEYS {
  First_Name = "first_name",
  Last_Name = "last_name",
  Age = "age",
  Cost = "cost",
  Birth_Date = "birth_date",
  SportType = "sport_type",
  Skill_Level = "skill_level",
  Date_Range = "date_range",
  Time = "time",
  Course_Duration = "course_duration",
  Cross_Selling_Products = "cross_selling_products",
  Product = "product",
  Products = "products",
}
