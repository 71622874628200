import { DateFormatter } from "react-day-picker";
import { format } from "date-fns";

const formatCaptionOneMonth: DateFormatter = (month, options) => {
  return (
    <>
      <span>{format(month, "LLL.", { locale: options?.locale })}</span>
      <span className="text-xs self-end p-2">
        {format(month, "y", { locale: options?.locale })}
      </span>
    </>
  );
};

const formatCaptionMultipleMonths: DateFormatter = (month, options) => {
  return format(month, "LLL y", { locale: options?.locale });
};

export const formatCaption = (months: number) => {
  return months === 1 ? formatCaptionOneMonth : formatCaptionMultipleMonths;
};

export const formatWeekdayName: DateFormatter = (weekday, options) => {
  return format(weekday, "ccccc", options);
};

export const formatButtonDate: DateFormatter = (month, options) => {
  return format(month, "dd.MM.y", { locale: options?.locale });
};

export const formatMonthCaption: DateFormatter = (month, options) => {
  return format(month, "LLL", { locale: options?.locale });
};

export const formatYearCaption: DateFormatter = (month, options) => {
  return format(month, "y", { locale: options?.locale });
};
