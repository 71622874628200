import { TeamMemberRowType } from "../../Types/CheckInTypes";
import { GoTrashcan } from "react-icons/go";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setCurrentIndex } from "../../Redux/TeamSlice";

const TeamMemberRow = ({
  firstName,
  lastName,
  index,
  age,
  onInputChange,
  onMemberDelete,
}: TeamMemberRowType) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className="bg-white h-fit w-full p-2 md:drop-shadow-xl flex justify-center items-center flex-col  lg:w-fit md:flex-row relative">
      <input
        type="text"
        placeholder={translate("first_name")}
        name="first_name"
        className="font-bold credentials p-1 m-2 outline-0 rounded-none border border-gray-400 w-full"
        value={firstName}
        onChange={(e) => onInputChange(e, index)}
      />
      <input
        type="text"
        placeholder={translate("last_name")}
        name="last_name"
        className="font-bold credentials mr-2 p-1 outline-0 rounded-none border border-gray-400 m-2 w-full"
        value={lastName}
        onChange={(e) => onInputChange(e, index)}
      />
      <div
        className="flex items-center justify-between my-2 md:m-2 gap-3 self-start"
        onClick={() => dispatch(setCurrentIndex(index))}
      >
        <label className="hover:cursor-pointer" htmlFor="date-age-modal">
          <div
            className={`credentials p-[5.67px] border border-gray-400 w-14 text-center  text-sm font-bold ${
              age ? "text-general" : "text-gray-300"
            }`}
          >
            {age ? age : translate("Alter")}
          </div>
        </label>
        <GoTrashcan
          size={25}
          className="hover:cursor-pointer"
          onClick={() => (onMemberDelete ? onMemberDelete(index) : undefined)}
        />
      </div>
    </div>
  );
};

export default TeamMemberRow;
