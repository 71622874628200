import {
  CrossSellingProductsProps,
  CrossSellingProductItemProps,
} from "../Types/CrossSellingProductsTypes";
import { capitalize, currencyFormat } from "../shared/helpers";
import { useTranslation } from "react-i18next";
import InfoButton from "../shared/InfoButton";
import {
  brightGreen,
  crossSellingProductsLinkPrefix,
} from "../shared/const-data";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";

const CrossSellingProductItem = ({
  crossSellingProduct,
  onProductSelectionChange,
  checked,
}: CrossSellingProductItemProps) => {
  return (
    <label
      htmlFor={crossSellingProduct.id.toString()}
      className="cursor-pointer"
    >
      <div className="w-full flex gap-4 items-center mb-1 sm:mb-2">
        <span id="Option">
          <input
            type="checkbox"
            id={crossSellingProduct.id.toString()}
            onChange={(e) => onProductSelectionChange(e, crossSellingProduct)}
            checked={checked}
            className="cursor-pointer"
          />
        </span>
        <div className="flex justify-between items-center w-full border border-gray-500 px-2 py-1 h-fit md:h-10">
          <span>{capitalize(crossSellingProduct.name)}</span>
          <div className=" flex justify-end items-center">
            <span>{currencyFormat(crossSellingProduct.price)}</span>
            <a
              href={crossSellingProductsLinkPrefix + crossSellingProduct.id}
              target="_blank"
              rel="noreferrer"
              className="ml-2 flex items-center"
            >
              <InfoButton fill={brightGreen} />
            </a>
          </div>
        </div>
      </div>
    </label>
  );
};

const CrossSellingProducts = ({
  products,
  onProductSelectionChange,
}: CrossSellingProductsProps) => {
  const { t: translate } = useTranslation();
  const currentIndex = useSelector(
    (state: RootState) => state.team.current_index
  );
  const member = useSelector(
    (state: RootState) => state.team.members[currentIndex]
  );

  return (
    <div className="text-gray-500 font-semibold text-xs sm:text-sm">
      {products.length > 0 && (
        <>
          <div className="mb-1">{translate("additional_offer")}</div>
          <div className="flex flex-col bg-white ">
            {products.map((crossSellingProduct, index) => {
              return (
                <CrossSellingProductItem
                  checked={
                    member.cross_selling_products
                      ? member.cross_selling_products.filter(
                          (product) => product.id === crossSellingProduct?.id
                        ).length !== 0
                      : false
                  }
                  key={index}
                  crossSellingProduct={crossSellingProduct}
                  onProductSelectionChange={onProductSelectionChange}
                />
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default CrossSellingProducts;
