import { de } from "date-fns/locale";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { editMember, resetSelections } from "../../Redux/TeamSlice";
import {
  dateFormatter,
  getAge,
  getLastAllowedBirthdate,
  toDateArgument,
} from "../../shared/helpers";
import DatePicker from "../DatePicker/DatePicker";
import { MEMBER_KEYS } from "../../shared/enums";

const AgePickerModal = () => {
  const currentIndex: number = useSelector(
    (state: RootState) => state.team.current_index
  );
  const member = useSelector(
    (state: RootState) => state.team.members[currentIndex]
  );
  const dateOfBirth = member?.birth_date;

  const [date, setDate] = useState<Date | undefined>();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  const onConfirmClick = () => {
    dispatch(
      editMember({
        index: currentIndex,
        key: MEMBER_KEYS.Birth_Date,
        value: dateFormatter(date),
      })
    );
    const age = getAge(date ? date : new Date());
    dispatch(
      editMember({ index: currentIndex, key: MEMBER_KEYS.Age, value: age })
    );
    if (dateFormatter(date) !== member?.birth_date) {
      dispatch(resetSelections(currentIndex));
    }
  };
  return (
    <div>
      <DatePicker
        label={translate("add_date_of_birth") + ":"}
        numberOfMonths={1}
        locale={de}
        captionLayout="dropdown"
        selectedDay={dateOfBirth ? new Date(toDateArgument(dateOfBirth)) : date}
        setSelectedDay={setDate}
        disabled={{ after: getLastAllowedBirthdate() }}
        onConfirmClick={onConfirmClick}
        labelIdForModal="date-age-modal"
        defaultMonth={getLastAllowedBirthdate()}
      />
    </div>
  );
};

export default AgePickerModal;
