import InfoIcon from "../Assets/Icons/InfoIcon";
import { MouseEventHandler } from "react";

type InfoButtonProps = {
  fill: string;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement> | undefined;
};

const InfoButton = ({ fill, onClick, onMouseEnter }: InfoButtonProps) => {
  return (
    <button
      className="align-middle focus:outline-none"
      onClick={onClick}
      onMouseEnter={onMouseEnter}
    >
      <InfoIcon fill={fill} />
    </button>
  );
};

export default InfoButton;
