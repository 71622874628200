import TeamMemberRow from "./TeamMemberRow";
import { AiOutlinePlus } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { TeamMemberContainerProps } from "../../Types/CheckInTypes";
import { useDispatch, useSelector } from "react-redux";
import { addEntry, editMember, deleteMember } from "../../Redux/TeamSlice";
import { useEffect, useRef } from "react";
import { RootState } from "../../Redux/store";
import { capitalize } from "../../shared/helpers";
import { Member } from "../../Types/MemberTypes";

const TeamMemberContainer = ({ setShow }: TeamMemberContainerProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const bottomRef = useRef<null | HTMLDivElement>(null);
  const members = useSelector((state: RootState) => state.team.members);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [members.length]);

  const onUserInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    dispatch(
      editMember({
        index: index,
        key: e.target.name,
        value: capitalize(e.target.value),
      })
    );
  };

  const onMemberDelete = (index: number) => {
    dispatch(deleteMember(index));
  };

  const onEntryAdd = () => {
    dispatch(addEntry());
  };

  return (
    <div className="bg-white h-fit  flex-v-center w-full lg:w-fit">
      <h1 className="font-bold w-full text-md  lg:text-xl  pl-2">
        {translate("add_people")}
      </h1>
      <div className="scrollbar max-h-[180px] md:max-h-[304px] w-full p-2  flex flex-col gap-2 drop-shadow-lg">
        {members.map((member: Member, index: number) => {
          return (
            <TeamMemberRow
              key={index}
              index={index}
              firstName={member.first_name}
              lastName={member.last_name}
              age={member.age}
              onInputChange={onUserInput}
              onMemberDelete={onMemberDelete}
            />
          );
        })}
        <div ref={bottomRef} />
      </div>
      <div className="flex-h-center pt-3 ">
        <button
          className="text-white btn-circle bg-bloody-red  h-10 w-10 border-none no-animation flex justify-center items-center"
          onClick={onEntryAdd}
        >
          <AiOutlinePlus size={43} />
        </button>
        <button
          onClick={() => setShow(false)}
          className="rounded-none border-none bg-bloody-red no-animation text-white font-bold ml-4 text-xl h-9 w-28"
        >
          {translate("continue")}
        </button>
      </div>
    </div>
  );
};

export default TeamMemberContainer;
