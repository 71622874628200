import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { BsChevronDoubleLeft } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../Redux/store";
import { setCurrentIndex } from "../Redux/TeamSlice";
import {
  getInitials,
  getStepItemStatus,
  getDisplayedItemsStartIndex,
} from "../shared/helpers";

type StepItemProps = {
  status: string;
  content: string;
  index: number;
};

const Stepper = () => {
  const [startIndex, setStartIndex] = useState<number>(0);
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const members = useSelector((state: RootState) => state.team.members);
  const currentIndex = useSelector(
    (state: RootState) => state.team.current_index
  );

  useEffect(() => {
    const startIndex = getDisplayedItemsStartIndex(currentIndex, members);
    setStartIndex(startIndex);
  }, [currentIndex, members]);

  const StepItem = ({ status, content, index }: StepItemProps) => {
    return (
      <span
        className="wrapper lg:wrapper-horizontal hover:cursor-pointer"
        onClick={() => {
          dispatch(setCurrentIndex(index));
        }}
      >
        <div className={"stepItem " + status}>
          <span className={"stepItem-content " + status}>
            <span className="stepItem-icon">{content}</span>
          </span>
        </div>
      </span>
    );
  };
  return (
    <div className="flex justify-start lg:justify-center items-center md:z-20">
      <div className="w-max min-w-0 max-w-[40px] lg:max-w-none lg:min-w-[400px] lg:px-10 lg:pb-10 lg:pt-4 h-max min-h-max lg:bg-white relative lg:drop-shadow-lg">
        <div className="text-center hidden lg:block lg:text-lg font-semibold mb-1">
          {translate("course_participants")}
        </div>
        <div className="stepItems lg:stepItems-horizontal text-center">
          <div className="text-2xl font-semibold text-center rotate-90 lg:rotate-0 lg:mr-4 justify-self-center flex-h-center">
            {members.length > 4 && (
              <button
                className=""
                onClick={() => {
                  currentIndex !== 0 &&
                    dispatch(setCurrentIndex(currentIndex - 1));
                }}
              >
                <BsChevronDoubleLeft className="w-full" />
              </button>
            )}
          </div>
          {members.map((member, index) => {
            const content: string = getInitials(
              member.first_name,
              member.last_name
            );
            const status = getStepItemStatus(index, currentIndex, member);
            if (index >= startIndex && index < startIndex + 4) {
              return (
                <StepItem
                  key={index}
                  status={status}
                  content={content}
                  index={index}
                />
              );
            }
            return null;
          })}
          <div
            className="plus-btn text-white  bg-bloody-red mt-8 lg:mt-0 lg:ml-16 cursor-pointer"
            onClick={() => navigate("/")}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Stepper;
