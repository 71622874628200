import { Product } from "../Types/MemberTypes";
import React, { useState } from "react";
import InfoButton from "../shared/InfoButton";
import ProductDetailModal from "./Modals/ProductDetailModal";
import { FaCheck } from "react-icons/fa";
import { useTranslation } from "react-i18next";

type ProductSlotOptionsProps = {
  selectedProduct?: Product | null;
  onSelect: (selectedProduct: Product) => void;
  products: Product[];
};

const classNames = (...classes: unknown[]): string =>
  classes.filter(Boolean).join(" ");

const ProductSlotOptions = ({
  selectedProduct,
  onSelect,
  products,
}: ProductSlotOptionsProps) => {
  const { t: translate } = useTranslation();
  const [visibleProductModal, setVisibleProductModal] =
    useState<Product | null>();

  const defaultStyle =
    "flex justify-between items-center w-full border border-gray-500 px-2 py-1 mb-2 h-fit md:h-10";
  const selectedStyle = " bg-bright-green text-white";

  return (
    <>
      <p className="text-gray-500 text-xs lg:text-sm font-semibold mb-1">
        {translate("select_the_course_you_want")}
      </p>
      {products.map((product, index) => {
        const isSelected = product === selectedProduct;
        return (
          <div
            key={index}
            className={classNames(defaultStyle, isSelected && selectedStyle)}
          >
            <p
              className="text-xs lg:text-sm font-semibold"
              onClick={() => {
                onSelect(product);
              }}
            >
              {product.name}
            </p>
            <div className="flex gap-2">
              <InfoButton
                fill={isSelected ? "white" : "#49b632"}
                onClick={() => setVisibleProductModal(product)}
              />
              <ProductDetailModal
                product={product}
                setShow={(s) => setVisibleProductModal(null)}
                show={visibleProductModal === product}
              />
              {isSelected && <FaCheck size={16} />}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default ProductSlotOptions;
