import Stepper from "../Components/Stepper";
import MembersForm from "../Components/MembersForm";
import DetailsWrapper from "./DetailsWrapper";

const TeamDetails = () => {
  return (
    <DetailsWrapper>
      <>
        <div className="bg-white md:shadow-lg  w-full md:w-8/12 h-fit md:mt-2 2xl:mt-16 absolute px-2 top-[4vh] md:top-[6.5vh] 2xl:top-[12.5vh] pl-[12%] md:pl-2 md:py-4 2xl:pt-8 lg:pb-24">
          <MembersForm />
          <div className="  lg:w-full h-min flex flex-col justify-center items-center absolute  top-2 left-1 md:-left-[25%] lg:left-0 lg:top-[calc(100%-70px)] lg:pb-10 ">
            <Stepper />
          </div>
        </div>
      </>
    </DetailsWrapper>
  );
};

export default TeamDetails;
