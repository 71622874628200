import { useLazyQuery, useQuery } from "@apollo/client";
import { de } from "date-fns/locale";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import { ActiveModifiers, DateRange } from "react-day-picker";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_BOOKING_PERIODS,
  GET_BOOKING_PRODUCTS,
  GET_START_DATES,
} from "../../Queries";
import { RootState } from "../../Redux/store";
import {
  editMember,
  resetSelections,
  setTotalCost,
} from "../../Redux/TeamSlice";
import {
  dateFormatter,
  dateToBackendFormat,
  getDatesInRange,
  toDateArgument,
} from "../../shared/helpers";
import DatePicker from "../DatePicker/DatePicker";
import Info from "../Info";
import { MEMBER_KEYS } from "../../shared/enums";
import { CrossSellingProduct } from "../../Types/CrossSellingProductsTypes";

type DateRangeModalProps = {
  onClose?: () => void;
};

const DateRangeModal = ({ onClose }: DateRangeModalProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const checkInDate = useSelector(
    (state: RootState) => state.team.checkin_date
  );
  const currentIndex = useSelector(
    (state: RootState) => state.team.current_index
  );
  const member = useSelector(
    (state: RootState) => state.team.members[currentIndex]
  );

  const [bookingPeriod, setBookingPeriod] = useState<Date[]>([]);
  const [duration, setDuration] = useState<number>(0);

  const selectedDates = useMemo(() => {
    const dates =
      member.date_range?.begin_date && member.date_range?.end_date
        ? getDatesInRange(
            new Date(toDateArgument(member.date_range?.begin_date)),
            new Date(toDateArgument(member.date_range?.end_date))
          )
        : [];
    return dates;
  }, [member.date_range]);

  const [datePickerLabel, setDatePickerLabel] = useState<string>(
    translate("choose_the_start_date_for_your_winter_experience")
  );

  const [dateRange, setDateRange] = useState<DateRange | undefined>();

  useEffect(() => {
    setDateRange({
      from: member?.date_range?.begin_date
        ? new Date(toDateArgument(member.date_range.begin_date))
        : undefined,
      to: member?.date_range?.end_date
        ? new Date(toDateArgument(member.date_range.end_date))
        : undefined,
    });
  }, [member?.date_range]);

  const [getBookingPeriod] = useLazyQuery(GET_BOOKING_PERIODS);
  const [getBookingProduct] = useLazyQuery(GET_BOOKING_PRODUCTS);
  const { data: startDatesData } = useQuery(GET_START_DATES, {
    variables: {
      input: {
        dateOfBirth: dateToBackendFormat(member.birth_date),
        checkinDate: dateToBackendFormat(checkInDate),
        sportType: member.sport_type?.id,
        skillLevel: member.skill_level?.uuid,
      },
    },
  });

  const formattedStartDates = useMemo(() => {
    return startDatesData?.startDates?.map((dateString: string) => {
      return new Date(dateString);
    }) as Date[];
  }, [startDatesData]);

  const onRangeSelection = (
    range: DateRange | undefined,
    day: Date,
    { disabled }: ActiveModifiers
  ) => {
    if (!disabled) {
      setDatePickerLabel(
        translate("choose_course_period_for_your_winter_experience")
      );
      setDateRange(range);
      getBookingPeriod({
        variables: {
          input: {
            dateOfBirth: dateToBackendFormat(member.birth_date),
            sportType: member.sport_type?.id,
            startDate: dateToBackendFormat(dateFormatter(range?.from)),
            skillLevel: member.skill_level?.uuid,
          },
        },
      }).then((response) => {
        setBookingPeriod(
          response.data?.bookingPeriods?.map((period: any) => {
            return new Date(toDateArgument(period.endDate));
          })
        );
        const selectedPeriod = response.data?.bookingPeriods?.find(
          (period: any) =>
            dateFormatter(new Date(toDateArgument(period?.endDate))) ===
            dateFormatter(range?.to)
        );
        setDuration(selectedPeriod?.durationInDays);
      });
    }
  };

  const onConfirmClick = () => {
    dispatch(resetSelections(currentIndex));

    dispatch(
      editMember({
        index: currentIndex,
        key: MEMBER_KEYS.Date_Range,
        value: {
          begin_date: dateFormatter(dateRange?.from),
          end_date: dateFormatter(dateRange?.to),
        },
      })
    );
    getBookingProduct({
      variables: {
        input: {
          dateOfBirth: dateToBackendFormat(member.birth_date),
          sportType: member.sport_type?.id,
          skillLevel: member.skill_level?.uuid,
          startDate: dateToBackendFormat(dateFormatter(dateRange?.from)),
          durationInDays: duration,
        },
      },
    }).then((response) => {
      const products = response.data?.bookingProducts.map(
        (currentProduct: any) => {
          // Map cross-selling products
          const mappedCrossSellingProducts: CrossSellingProduct =
            currentProduct?.crossSellingProducts?.map(
              (crossSellingProduct: any) => {
                return {
                  name: crossSellingProduct.product.name,
                  id: crossSellingProduct.product.id,
                  saProductUuid: crossSellingProduct.saProductUuid,
                  price: crossSellingProduct.price.price.totalPrice,
                  sessionUuid: crossSellingProduct.price.sessionUuid,
                  priceListUuid: crossSellingProduct.price.priceListUuid,
                };
              }
            );

          return {
            name: currentProduct?.product.name,
            descriptionHtml: currentProduct?.product.description,
            id: currentProduct?.product.id,
            saProductUuid: currentProduct?.saProductUuid,
            price: currentProduct?.price.price.totalPrice,
            available_times: currentProduct?.startTimes,
            sessionUuid: currentProduct?.price.sessionUuid,
            priceListUuid: currentProduct?.price.priceListUuid,
            available_cross_selling_products: mappedCrossSellingProducts,
          };
        }
      );

      dispatch(
        editMember({
          index: currentIndex,
          key: MEMBER_KEYS.Products,
          value: products,
        })
      );
      dispatch(
        editMember({
          index: currentIndex,
          key: MEMBER_KEYS.Course_Duration,
          value: duration,
        })
      );

      if (products?.[0].available_times?.length === 1) {
        dispatch(
          editMember({
            index: currentIndex,
            key: MEMBER_KEYS.Time,
            value: products[0].available_times[0],
          })
        );
      }
      dispatch(setTotalCost(currentIndex));
      onClose?.();
    });
  };

  const onResetClick = () => {
    setDatePickerLabel(
      translate("choose_the_start_date_for_your_winter_experience")
    );
    setDateRange({ from: undefined, to: undefined });
    setDuration(0);
  };

  const allowedDates = useMemo(() => {
    if (dateRange?.from && dateRange?.to) {
      return selectedDates;
    }
    if (dateRange?.from) {
      return bookingPeriod;
    }
    return formattedStartDates;
  }, [dateRange, bookingPeriod, selectedDates, formattedStartDates]);

  return (
    <div className="modal modal-middle">
      <div className="w-screen  h-screen bg-[#fafafa] flex-v-center">
        <div className="w-screen flex flex-col items-center gap-10 my-4 sm:my-0 max-w-screen-xl h-max overflow-y-auto">
          <DatePicker
            label={datePickerLabel}
            locale={de}
            numberOfMonths={2}
            onDateRangeSelect={onRangeSelection}
            onResetClick={onResetClick}
            onCloseClick={onClose}
            allowedDates={allowedDates}
            startMonth={
              formattedStartDates ? formattedStartDates[0] : new Date()
            }
            dateRange={dateRange}
            setDateRange={setDateRange}
            labelIdForModal={"date-range-modal"}
            onConfirmClick={onConfirmClick}
            confirmButtonText={
              duration
                ? `${translate("confirm_days", { days: duration })}`
                : null
            }
          />
          <Info />
        </div>
      </div>
    </div>
  );
};

export default DateRangeModal;
