import { MembersModalProps } from "../../Types/CheckInTypes";
import TeamMemberContainer from "../TeamMembers/TeamMemberContainer";
import AgePickerModal from "./AgePickerModal";

const MembersModal = ({ show, setShow }: MembersModalProps) => {
  return (
    <div
      className={`absolute bottom-7 left-[2.5%] lg:left-[37%] lg:right-[3%] w-[95%] lg:w-[60%] z-10 shadow-lg p-4 bg-white flex flex-col items-center ${
        show ? "block" : "hidden"
      }`}
    >
      <div>
        <label htmlFor="date-age-modal">
          <input
            type="checkbox"
            id="date-age-modal"
            className="modal-toggle bg-transparent"
          />
          <div className="modal modal-middle backdrop-blur-sm">
            <AgePickerModal />
          </div>
        </label>
      </div>
      <TeamMemberContainer setShow={setShow} />
    </div>
  );
};

export default MembersModal;
