import InfoIcon from "../Assets/Icons/InfoIcon";
import { useTranslation } from "react-i18next";
const Info = () => {
  const { t: translate } = useTranslation();
  return (
    <div className="w-full md:w-4/5  h-34 xl:h-40 bg-white md:shadow-lg p-4 flex-h-center">
      <div className="w-[85%] flex flex-col gap-2">
        <div className="flex items-center gap-2">
          <InfoIcon fill="#f01a1c" />
          <p className="font-bold text-xs md:text-sm xl:text-lg ">
            {translate("general_information")}
          </p>
        </div>
        <div className="font-sans text-xs xl:text-base w-fit pl-7">
          <p className="leading-tight">
            {translate("minimum_participants")}
            <br />
            {translate("group_merge")}
            <br />
            {translate("no_saturday")}
            <br />
            {translate("private_courses")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Info;
