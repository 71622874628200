import { gql } from "@apollo/client";

export const ADD_TO_CART = gql`
  mutation addToCart($items: [CartItemInput!]!) {
    addToCart(items: $items) {
      token
      price {
        positionPrice
        totalPrice
      }
    }
  }
`;
