import logo from "../Assets/Large.png";
import useWindowSize from "../Hooks/useWindowSize";
import watermark from "../Assets/styleflasher_watermark.svg";

type Props = {
  children: JSX.Element;
};
const DetailsWrapper = ({ children }: Props) => {
  const mediumScreenSize = 768;
  const width = useWindowSize();

  return (
    <div className="h-screen w-screen flex-h-center ">
      <div className="w-[90%] md:w-[85%] h-[90%] 2xl:h-3/4 flex flex-col items-center ">
        <div className="styleflasher-bg-2">
          <img
            src={width >= mediumScreenSize ? logo : ""}
            alt=""
            className=" h-0 md:h-auto max-h-full w-full "
          ></img>
          <a className="flex-col absolute z-50 mt-2 hidden xl:flex"
             href="https://www.styleflasher.at/" target="_blank" rel="noreferrer">
            <p className="font-bold text-[7px] -mb-2">MADE WITH STYLE BY</p>
            <img
                src={watermark}
                alt="styleflasher Logo"
                className="w-[110px] h-7 bg-no-repeat"
            ></img>
          </a>
        </div>
        <div className="w-full flex justify-center">{children}</div>
      </div>
    </div>
  );
};

export default DetailsWrapper;
